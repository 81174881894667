import React from 'react'
import {
  Subheading,
  Card,
  FormLayout,
  TextField,
  TextStyle,
  TextContainer,
  Stack,
  Icon,
  RadioButton,
} from "@shopify/polaris"
import styled from 'styled-components'
import update from 'immutability-helper'
import ManualRules from './manualRules'
import OfferTypeSelector from '../common/offerTypeSelector/offerTypeSelector'
import OfferTypeCheckbox from "../common/offerTypeCheckbox/offerTypeCheckbox";
const CheckoutOfferTypeWrapper = styled.div`
    margin-bottom: 20px;
    .rule-type {
        padding-top: 1.6rem;
        .rule-type-subtitle {
            margin: 0px 0 20px;
        }
        .rule-type-option {
            border: 2px solid #AAA;
            border-radius: 4px;
            padding: 10px 10px;
            margin-bottom: 25px;
            .rule-type-image {
                text-align: center;
                img {
                    width: 85px;
                }
            }
            .rule-type-image:nth-of-type(2) {
                img {
                    width: 96px;
                }
            }
            .rule-type-desp {
                max-width: 210px;
            }
        }
        .active {
            border: 2px solid #008060;
        }
    }
`
function CheckoutOfferType(props) {
    
    const { host, checkoutOfferType, handleCheckoutOfferTypeChange, shop, addSmartRule, state, setState, productPickerShow, setProductPickerShow, error, productEdit, setProductEdit, currency, currencySymbol, locale, versionAnalytics, versionData, setVersionData, version, setVersion, token, setCheckoutOfferType, existingOffer, customizationEdit, setCustomizationEdit } = props
    
    return (
        <CheckoutOfferTypeWrapper>
        <Card sectioned>
           <FormLayout>
            <Subheading>Name Your offer</Subheading>
            <TextField
                    label="Internal offer nickname" 
                    placeholder="e.g. Holiday Promo 2023" 
                    labelHidden={true}
                    onChange={(val) => {
                        setState(update(state, { name: { $set: val }}))
                    }}
                    value={state.name}
                />
               {state.id ?
                 <TextContainer spacing="tight">
                   <TextStyle variation="subdued">Offer ID: {state.id}</TextStyle>
                 </TextContainer>
                 :
                 false
               }
           </FormLayout>
           {/*<OfferTypeSelector*/}
           {/*     checkoutOfferType={checkoutOfferType}*/}
           {/*     setCheckoutOfferType={setCheckoutOfferType}*/}
           {/*     state={state}*/}
           {/*     setState={setState}*/}
           {/*     existingOffer={existingOffer}*/}
           {/* />*/}
            <OfferTypeCheckbox
              offerType={checkoutOfferType}
              changeOfferType={handleCheckoutOfferTypeChange}
            />
        </Card>
        <ManualRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol} locale={locale} versionAnalytics={versionAnalytics} versionData={versionData} setVersionData={setVersionData} version={version} setVersion={setVersion} token={token} checkoutOfferType={checkoutOfferType} existingOffer={existingOffer} customizationEdit={customizationEdit} setCustomizationEdit={setCustomizationEdit}/>
        </CheckoutOfferTypeWrapper>
    )
}

export default CheckoutOfferType;